import { useCallback, useEffect, useState } from "react";
import wp from "wp";
/**
 * @param chunkFile Use `__webpack_get_script_filename__`
 */

function useChunkTranslation(chunkFile, {
  chunks,
  publicUrl,
  textDomain,
  version
}) {
  const suffixes = chunks[chunkFile.split("?")[0]]; // We need to remove the query argument from `output.chunkFilename`

  const exists = !!suffixes;
  const [done, setDone] = useState(false);
  const fetchTranslationBySuffix = useCallback(async suffix => {
    // Do never load twice
    const w = window;
    w.wpi18nLazy = w.wpi18nLazy || {};
    w.wpi18nLazy.chunkUrls = w.wpi18nLazy.chunkUrls || [];
    const {
      chunkUrls
    } = w.wpi18nLazy;
    const urlToJson = "".concat(publicUrl, "languages/json/").concat(textDomain, "-").concat(suffix, ".json");

    if (exists && chunkUrls.indexOf(urlToJson) === -1) {
      chunkUrls.push(urlToJson);

      try {
        const response = await window.fetch("".concat(urlToJson, "?ver=").concat(version));
        const {
          locale_data: {
            messages
          }
        } = await response.json();
        wp.i18n.setLocaleData(messages, textDomain);
      } catch {// Silence is golden.
      }
    }
  }, [publicUrl, textDomain]);
  useEffect(() => {
    if (exists) {
      Promise.all(suffixes.map(fetchTranslationBySuffix)).then(() => {
        setDone(true);
      });
    } else {
      setDone(true);
    }
  }, [exists, fetchTranslationBySuffix]);
  return done;
}

export { useChunkTranslation };