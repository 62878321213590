/**
 * See PHP file inc/Assets.php.
 */
class BaseOptions {
  constructor() {
    this.slug = void 0;
    this.textDomain = void 0;
    this.version = void 0;
    this.restUrl = void 0;
    this.restNamespace = void 0;
    this.restRoot = void 0;
    this.restQuery = void 0;
    this.restNonce = void 0;
    this.restRecreateNonceEndpoint = void 0;
    this.publicUrl = void 0;
    this.chunkFolder = void 0;
    this.chunks = void 0;
  }

  /**
   * Convert a slug like "my-plugin" to "myPlugin". This can
   * be useful for library naming (window[""] is bad because the hyphens).
   */
  static slugCamelCase(slug) {
    return slug.replace(/-([a-z])/g, g => g[1].toUpperCase());
  }
  /**
   * Get the slug from the current process (webpack) instead of the PHP plugin output.
   * For some cases you need to use that.
   */


  static getPureSlug(slug, camelCase = false) {
    return camelCase ? BaseOptions.slugCamelCase(slug) : slug;
  }

}

export { BaseOptions };