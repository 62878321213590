import _initializerDefineProperty from "@babel/runtime/helpers/initializerDefineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";
import _initializerWarningHelper from "@babel/runtime/helpers/initializerWarningHelper";

var _class, _descriptor, _descriptor2, _class2, _temp;

import { RouteHttpVerb } from "../../factory";
import { observable, flow } from "mobx";
let ClientCollection = (_class = (_temp = _class2 = class ClientCollection {
  constructor() {
    _initializerDefineProperty(this, "entries", _descriptor, this);

    _initializerDefineProperty(this, "busy", _descriptor2, this);

    this.annotated = void 0;
    this.get = flow(function* (data) {
      const {
        request,
        params,
        clear = false
      } = data || {};
      this.busy = true;

      try {
        const {
          path,
          namespace
        } = this.annotated;
        const response = yield this.annotated.request({
          location: {
            path,
            method: RouteHttpVerb.GET,
            namespace
          },
          request,
          params
        }); // Save cookies as models

        clear && this.entries.clear();

        for (const row of response) {
          const instance = this.instance(row);
          const existing = this.entries.get(instance.key);

          if (!existing) {
            this.entries.set(instance.key, instance);
          } else {
            // Update stale data
            existing.data = instance.data;
          }
        }
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        this.busy = false;
      }
    });
    this.getSingle = flow(function* (data) {
      if (!this.annotated.singlePath) {
        throw new Error("There is no getSingle method allowed");
      }

      const {
        request,
        params
      } = data || {};
      this.busy = true;

      try {
        const {
          singlePath,
          namespace
        } = this.annotated;
        const row = yield this.annotated.request({
          location: {
            path: singlePath,
            method: RouteHttpVerb.GET,
            namespace
          },
          request,
          params
        }); // Save cookie as model

        const instance = this.instance(row);
        this.entries.set(instance.key, instance);
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        this.busy = false;
      }
    });
    setTimeout(() => {
      if (!this.annotated) {
        console.error("You have not used the @ClientCollection.annotate annoation together with this class!");
      }
    }, 0);
  }

}, _class2.annotate = annotate => {
  return constructor => {
    return class extends constructor {
      constructor(...args) {
        super(...args);
        this.annotated = annotate;
      }

    };
  };
}, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "entries", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return new Map();
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "busy", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
})), _class);
export { ClientCollection };