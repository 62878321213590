import _initializerDefineProperty from "@babel/runtime/helpers/initializerDefineProperty";
import _applyDecoratedDescriptor from "@babel/runtime/helpers/applyDecoratedDescriptor";
import _initializerWarningHelper from "@babel/runtime/helpers/initializerWarningHelper";

var _class, _descriptor, _descriptor2, _descriptor3, _class2, _temp;

import { RouteHttpVerb } from "../../factory";
import { observable, computed, set, flow, runInAction } from "mobx";
let ClientModel = (_class = (_temp = _class2 = class ClientModel {
  get key() {
    var _this$data;

    return (_this$data = this.data) === null || _this$data === void 0 ? void 0 : _this$data[this.annotated.keyId];
  }

  constructor(collection, data = {}) {
    _initializerDefineProperty(this, "data", _descriptor, this);

    _initializerDefineProperty(this, "collection", _descriptor2, this);

    _initializerDefineProperty(this, "busy", _descriptor3, this);

    this.annotated = void 0;
    this.persist = flow(function* (params) {
      if (!this.annotated.create) {
        throw new Error("There is no persist method allowed");
      }

      this.busy = true;

      try {
        const {
          create: {
            path,
            method
          },
          namespace
        } = this.annotated;
        const response = yield this.annotated.request({
          location: {
            path,
            method: method || RouteHttpVerb.POST,
            namespace
          },
          request: this.transformDataForPersist(),
          params: params || {}
        });
        this.fromResponse(response);
        this.collection.entries.set(this.key, this);
        this.afterPersist();
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        this.busy = false;
      }
    });
    this.patch = flow(function* (params) {
      if (!this.annotated.patch) {
        throw new Error("There is no patch method allowed");
      }

      this.busy = true;

      try {
        const {
          patch: {
            path,
            method
          },
          namespace
        } = this.annotated;
        const response = yield this.annotated.request({
          location: {
            path,
            method: method || RouteHttpVerb.PATCH,
            namespace
          },
          request: this.transformDataForPatch(),
          params: { ...{
              [this.annotated.keyId]: this.key
            },
            ...(params || {})
          }
        });
        this.fromResponse(response);
        this.afterPatch();
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        this.busy = false;
      }
    });
    this.delete = flow(function* (params) {
      if (!this.annotated.delete) {
        throw new Error("There is no delete method allowed");
      }

      this.busy = true;

      try {
        const {
          delete: {
            path,
            method
          },
          namespace
        } = this.annotated;
        const response = yield this.annotated.request({
          location: {
            path,
            method: method || RouteHttpVerb.DELETE,
            namespace
          },
          params: { ...{
              [this.annotated.keyId]: this.key
            },
            ...(params || {})
          }
        });
        this.collection.entries.delete(this.key);
        this.afterDelete();
        return response;
      } catch (e) {
        console.log(e);
        throw e;
      } finally {
        this.busy = false;
      }
    });
    setTimeout(() => {
      if (!this.annotated) {
        console.error("You have not used the @ClientModel.annotate annoation together with this class!");
      }
    }, 0);
    runInAction(() => {
      this.collection = collection;
      this.data = data;
    });
  }

  fromResponse(response) {
    set(this.data, response);
    return this;
  } // eslint-disable-next-line @typescript-eslint/member-ordering


  /**
   * Transform the class-hold data to POSTable data. This can be useful if e. g.
   * one property differs from the response property schema.
   */
  transformDataForPersist() {
    return this.data;
  }
  /**
   * Create your conditionals here and return only changed values.
   */


  transformDataForPatch() {
    throw new Error("If you want to use patch method, you need to implement transformDataForPatch!");
  }

  afterPersist() {// Silence is golden.
  }

  afterPatch() {// Silence is golden.
  }

  afterDelete() {// Silence is golden.
  }

}, _class2.annotate = annotate => constructor => {
  return class extends constructor {
    constructor(...args) {
      super(...args);
      this.annotated = annotate;
    }

  };
}, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "data", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return {};
  }
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "collection", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "busy", [observable], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: function () {
    return false;
  }
}), _applyDecoratedDescriptor(_class.prototype, "key", [computed], Object.getOwnPropertyDescriptor(_class.prototype, "key"), _class.prototype)), _class);
export { ClientModel };