import { ClientModel } from "..";

class AbstractPost extends ClientModel {
  transformDataForPersist() {
    var _data$title, _data$content;

    const data = { ...super.transformDataForPersist()
    };
    data.title = (_data$title = data.title) === null || _data$title === void 0 ? void 0 : _data$title.rendered;
    data.content = (_data$content = data.content) === null || _data$content === void 0 ? void 0 : _data$content.rendered;
    delete data._links;
    delete data.link;
    return data;
  }

  transformDataForPatch() {
    return this.transformDataForPersist();
  }

}

export { AbstractPost };